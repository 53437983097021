import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { appConfig } from 'src/config';

const Logo = props => {
  const { user } = useAuth();
  if (!user) return null

  const path = user.logo ? `/static/${user.logo}` : appConfig.logoPath
  return <img alt="Logo" src={path} style={{ maxHeight: "80px" }} {...props} referrerpolicy="no-referrer" />;

};

export default Logo;
