import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SlideOnStart from 'src/components/SliderOnStart';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import { createThemeExport as createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { Capacitor } from '@capacitor/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken
} from 'firebase/messaging';
import { Box, Typography } from '@mui/material';

import { appConfig } from './config';
import { Toaster } from 'react-hot-toast';
import { getLaguages } from 'src/slices/availableLanguages';
import { useDispatch } from 'src/store';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const history = createBrowserHistory();
const App = () => {
  const { settings, saveSettings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLaguages());
  }, [dispatch]);

  const Msg = ({ titolo, body }) => {
    return (
      <Box display="flex" flexDirection="column">
        <strong>{titolo}</strong>
        <Typography>{body}</Typography>
      </Box>
    );
  };

  if (appConfig.enablePushNotifications) {
    try {
      const messaging = getMessaging();
      onMessage(messaging, payload => {
        console.log('Message received. ', payload);
        toast(<Msg titolo={payload.data.title} body={payload.data.body} />);
      });
    } catch { }
  }

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      saveSettings({ sliderShown: false });
    }
    if (settings.lang == 'ar') {
      document.body.dir = 'rtl';
    } else {
      document.body.dir = 'ltr';
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
          <Router history={history}>
            <AuthProvider>
              <ToastContainer position="top-center" />
              <Toaster
                position="top-center"
                toastOptions={{
                  duration: 5000,
                  style: {
                    color: theme.palette.primary.main
                  }
                }}
              />
              <GlobalStyles />
              <ScrollReset />
              <GoogleAnalytics />
              <SlideOnStart />
              {renderRoutes(routes)}
            </AuthProvider>
          </Router>
          {/* </MuiPickersUtilsProvider> */}
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
