import yn from 'yn';
export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: 'AIzaSyCZKPFvdyhT90zms3-qgJwrh7mW2kDYQJg', //process.env.REACT_APP_FIREBASE_API_KEY,
  appId: '1:662358465117:web:9825805538362d4210cbb4', //process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: 'procs-b0af5.firebaseapp.com', //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: '',
  messagingSenderId: '662358465117', //process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: 'procs-b0af5', //process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'procs-b0af5.appspot.com' //process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const vapidkey = process.env.REACT_APP_VAPID_KEY;

export const loginConfig = {
  clientId: process.env.REACT_APP_GOOGLE_OAUTH2_KEY,
  type: process.env.REACT_APP_LOGIN_TYPE
};

export const viewConfig = {
  settings: yn(process.env.REACT_APP_SETTINGS_VISIBLE || false),
  theme: yn(process.env.REACT_APP_SETTINGS_THEME_VISIBLE || false),
  kanban: yn(process.env.REACT_APP_KANBAN_VISIBLE || false),
  notifications: yn(process.env.REACT_APP_SAMPLE_NOTIFCATION_VISIBLE || false),
  account: yn(process.env.REACT_APP_ACCOUNT_VISIBLE || true),
  dashboardButtons: yn(process.env.REACT_APP_DASHBOARD_BUTTONS_VISIBLE || false)
};

export const appConfig = {
  restPrefix: process.env.REACT_APP_REST_PREFIX || 'http://localhost:8094/',
  displayCookie: yn(process.env.REACT_APP_DISPLAY_COOKIES || false),
  availableLanguages: process.env.REACT_APP_AVAILABLE_LANGUAGES || 'it',
  profilo: process.env.REACT_APP_PROFILO || 'UTENTE',
  displayPopup: yn(process.env.REACT_APP_PRIVACY_POPUP || true),
  logoPath: process.env.REACT_APP_LOGO_PATH || '/static/LogoProcs.png',
  displaySlide: yn(process.env.REACT_APP_DISPLAY_SLIDE || false),
  nonChiederePiuSlider: yn(
    process.env.REACT_APP_NON_CHIEDERE_PIU_SLIDER || false
  ),
  idFrontEnd: process.env.REACT_APP_IDFRONTEND || 'WEB',
  defaultTheme: process.env.REACT_APP_THEME_DEFAULT || 'DEMO1',
  linkLogo: process.env.REACT_APP_LINK_LOGO || '',
  displaySelectLanguages: yn(process.env.REACT_APP_SELECT_LANGUAGE || false),
  notificationsVisible: yn(
    process.env.REACT_APP_ACCOUNT_NOTIFICATIONS_VISIBLE || false
  ),
  emailEnabled: yn(process.env.REACT_APP_ACCOUNT_EMAIL_ENABLED || true),
  appName: process.env.REACT_APP_APPLICATION_NAME || 'Procs',
  linkToLogin: yn(process.env.REACT_APP_LINK_TO_LOGIN || true),
  languageOnRegistration: yn(
    process.env.REACT_APP_LANGUAGE_ON_REGISTRATION || false
  ),
  displayPrivacyAgree: yn(process.env.REACT_APP_PRIVACY_AGREE || false),
  privacyLink: process.env.REACT_APP_PRIVACY_link || '#',
  searchInDashboardVisible: yn(process.env.REACT_APP_SEARCH_VISIBLE || true),
  timeLinePast: process.env.REACT_APP_COLOR_TM_PAST || 'green',
  timeLinePresent: process.env.REACT_APP_COLOR_TM_PRESENT || '#ebba34',
  timeLineFuture: process.env.REACT_APP_COLOR_TM_FUTURE || 'grey',
  timeLineError: process.env.REACT_APP_COLOR_TM_ERROR || 'red',
  showindexIconDashboard: yn(process.env.REACT_APP_SHOW_INDEX_DB || true),
  showReadingModeBold: yn(process.env.REACT_APP_SHOW_RM_BOLD || true),
  procModificaProfilo: process.env.REACT_APP_PROC_PROFILO || '',
  stepModificaProfilo: process.env.REACT_APP_STEP_PROFILO || '',
  enablePushNotifications: yn(
    process.env.REACT_APP_ENABLE_PUSH_NOTIFICATIONS || false
  ),
  showNoNotifications: yn(process.env.REACT_APP_SHOW_NO_NOTIFICATIONS || false),

  versionNumber: process.env.REACT_APP_VERSION_NUMBER || "",
  linkIOSapp: process.env.REACT_APP_LINK_IOS_APP || "#",
  linkANDROIDapp: process.env.REACT_APP_LINK_ANDROID_APP || "#",
  showSpidButton: yn(process.env.REACT_APP_SPID_LOGIN || false),
  displayAnonymousLogin: yn(process.env.REACT_APP_ANONYMOUS_ACCESS || false),
  ambiente: process.env.REACT_APP_AMBIENTE || null,
  showPoweredBy: yn(process.env.REACT_APP_SHOW_POWEREDBY || false)
};
