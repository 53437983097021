import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import { appConfig } from 'src/config';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageButton from 'src/components/Languages/LanguageButton'
const linkLogo = appConfig.linkLogo;

const languages = {
  en: '/static/icons/uk_flag.svg',
  it: '/static/icons/it_flag.svg',
  fr: '/static/icons/fr_flag.svg',
  ar: '/static/icons/ar_flag.svg',
  pu: '/static/icons/pu_flag.svg',
  de: '/static/icons/de_flag.svg',
  es: '/static/icons/es_flag.svg',

};
const displaySL = appConfig.displaySelectLanguages;

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 'env(safe-area-inset-top)',
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.appBarText.main
    // ...(theme.name === THEMES.LIGHT
    //   ? {
    //       boxShadow: 'none',
    //       backgroundColor: theme.palette.primary.main
    //     }
    //   : {}),
    // ...(theme.name === THEMES.ONE_DARK
    //   ? {
    //       backgroundColor: theme.palette.background.default
    //     }
    //   : {})
    // boxShadow: 'none',
  },
  toolbar: {
    minHeight: 64
  }
}));

// const LanguageButton = () => {
//   const anchorRef = useRef(null);
//   const { i18n } = useTranslation();
//   const [openPopover, setOpenPopover] = useState(false);

//   const handleOpenPopover = () => {
//     setOpenPopover(true);
//   };

//   const handleClosePopover = () => {
//     setOpenPopover(false);
//   };

//   return (
//     <>
//       <IconButton
//         onClick={handleOpenPopover}
//         ref={anchorRef}
//         sx={{ ml: 1 }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             height: 20,
//             width: 20,
//             '& img': {
//               width: '100%'
//             }
//           }}
//         >
//           <img
//             alt=""
//             src={languages[i18n.language]}
//           />
//         </Box>
//       </IconButton>
//       <LanguagePopover
//         anchorEl={anchorRef.current}
//         onClose={handleClosePopover}
//         open={openPopover}
//       />
//     </>
//   );
// };

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [isDesigner, setisDesigner] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('ProcesDesign')) {
      setisDesigner(true);
    } else {
      setisDesigner(false);
    }
  }, [location.pathname]);

  return (
    <AppBar
      className={classes.root}
    // sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Toolbar className={classes.toolbar}>
        {isDesigner ? (
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        ) : (
          <>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                onClick={onMobileNavOpen}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            </Hidden>
            <Hidden xlDown>
              {linkLogo ? (
                <a href={linkLogo}>
                  <Logo />
                </a>
              ) : (
                <RouterLink to="/">
                  <Logo />
                </RouterLink>
              )}
            </Hidden>
          </>
        )}

        <Box ml={2} flexGrow={1} />
        <Search />
        {/*<Contacts />*/}
        <Notifications />
        <Settings />
        {displaySL && <LanguageButton />}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
