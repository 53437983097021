import React from 'react';

import request from 'superagent';

import { Icon } from '@mui/material';
import { appConfig } from 'src/config';

require('dotenv').config();

const restPrefix = appConfig.restPrefix;
const locationPrefix = process.env.REACT_APP_LOCATION_PREFIX;

const warningIcon = (
  <Icon
    children="fiber_manual_record"
    style={{ color: 'orange ' }}
    fontSize="small"
  ></Icon>
);
const errorIcon = (
  <Icon
    children="fiber_manual_record"
    style={{ color: 'red ' }}
    fontSize="small"
  ></Icon>
);

const goodIcon = (
  <Icon
    children="fiber_manual_record"
    style={{ color: 'green ' }}
    fontSize="small"
  ></Icon>
);

const getCookie = cname => {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

const delete_cookie = name => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
};

const checkUser = (userName, psw, Gjwt) => {
  return request
    .post(restPrefix + 'login')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      id: userName,
      password: psw,
      Gjwt: Gjwt
    })
    .then(json => {
      let name = json.body.user;
      let jwt = json.body.jwt;
      let ruoli = JSON.stringify(json.body.response.functions);
      let color = json.body.response.primaryColor;
      let companyDescription = json.body.response.companyDescription;
      let mail = json.body.response.mail;
      setCookie('name', name, 1);
      setCookie('jwt', jwt, 1);
      setCookie('ruoli', ruoli, 1);
      setCookie('color', color || '#152E4C', 1);
      setCookie('companyDescription', companyDescription, 1);
      setCookie('mail', mail, 1);
      return json.body;
    })
    .catch(error => {
      return error;
    });
};

const classicLogin = (userName, psw, Gjwt) => {
  return request
    .post(restPrefix + 'login')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      id: userName,
      password: psw,
      Gjwt: Gjwt
    });
};

const HelloWorld = (userName, psw, Gjwt) => {
  return request
    .post('http://localhost:3030/login')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', 'http://localhost:3030/')
    .send({
      id: 'riccardo.b',
      password: 'b',
      Gjwt: ''
    });
};

const loginWithToken = token => {
  return request
    .post(restPrefix + '/LOGIN/EMAILTOKEN')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      token: token
    })
    .then(json => {
      let name = json.body.user;
      let jwt = json.body.jwt;
      let ruoli = JSON.stringify(json.body.response.functions);
      let color = json.body.response.primaryColor;
      let companyDescription = json.body.response.companyDescription;
      let mail = json.body.response.mail;
      setCookie('name', name, 1);
      setCookie('jwt', jwt, 1);
      setCookie('ruoli', ruoli, 1);
      setCookie('color', color || '#152E4C', 1);
      setCookie('companyDescription', companyDescription, 1);
      setCookie('mail', mail, 1);
      return json.body;
    })
    .catch(error => {
      return error;
    });
};

const registerUser = (firstName, email, psw, lingua, token) => {
  return request
    .post(restPrefix + 'LOGIN/NEWUSER')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      email: email,
      firstName: firstName,
      password: psw,
      language: lingua,
      token: token
    })
    .then(json => {
      return json.body;
    })
    .catch(error => {
      return error.response.body;
    });
};

const confirmUser = (email, token) => {
  return request
    .post(restPrefix + 'LOGIN/CONFIRMUSER')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      email: email,
      token: token
    })
    .then(json => {
      return json.body;
    })
    .catch(error => {
      return error.response.body;
    });
};

const lostPsw = email => {
  return request
    .post(restPrefix + 'LOGIN/NOPASSWORD')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      email: email
    })
    .then(json => {
      return json.body;
    })
    .catch(error => {
      return error.response.body;
    });
};

const newPsw = (email, token, psw) => {
  return request
    .post(restPrefix + 'LOGIN/NEWPASSWORD')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      email: email,
      token: token,
      newpassword: psw
    })
    .then(json => {
      return json.body;
    })
    .catch(error => {
      return error.response.body;
    });
};

const changePsw = (email, oldPsw, psw) => {
  return request
    .post(restPrefix + 'LOGIN/CHANGEPASSWORD')
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .send({
      email: email,
      oldpassword: oldPsw,
      newpassword: psw
    })
    .then(json => {
      return json.body;
    })
    .catch(error => {
      return error.response.body;
    });
};

const search = stringSearch => {
  return request
    .get(restPrefix + 'procs/search/' + stringSearch)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .then(json => {
      return json.body.response;
    })
    .catch(error => {
      return error;
    });
};

const varChanged = (proces, step, group, key, variables, notify) => {
  return request
    .post(
      restPrefix +
        'procs/event/' +
        proces +
        '/' +
        step +
        '/' +
        (group === undefined ? ' ' : group) +
        '/' +
        key +
        '/' +
        notify +
        '/'
    )
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .send(variables)
    .then(json => {
      console.log(json.body.response);
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json.body.response;
    })
    .catch(e => console.log(e));
};

// set('jwt', window.localStorage.getItem('accessToken'))
//     .set('Content-Type', 'application/x-www-form-urlencoded')
//     .set('Access-Control-Expose-Headers', locationPrefix)
//     .set('hasFile', hasFile)
//     .type('json')
//     .send(response.variables)

const getPageFromRest = (proces, step, group) => {
  return request
    .post(restPrefix + 'procs/new/' + proces + '/' + step + '/' + group)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      console.log(json.body.response);
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error;
    });
};
const getPageFromNotify = notify => {
  return request
    .post(restPrefix + 'procs/fromNotify/' + notify)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      console.log(json.body.response);
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error;
    });
};

const opportuniyList = () => {
  return request
    .get(restPrefix + 'procs/opportunity/list/')
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }

      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json.body.response;
    });
};

const getfromRagg = id => {
  return request
    .post(restPrefix + 'procs/fromRagg/OPP' + id)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix);
};

// const getProcessDesign = () => {
//   return request
//     .post(restPrefix + 'processDesign/')
//     .set('jwt', window.localStorage.getItem('accessToken'))
//     .set('Content-Type', 'application/x-www-form-urlencoded')
//     .set('Access-Control-Expose-Headers', locationPrefix)
//     .then((json) => {
//       const jwt = json.body.jwt;
//       if (jwt !== '') {
//         localStorage.setItem('accessToken', jwt);
//       }
//       return json.body.response;
//     });
// };
const saveProcs = processi => {
  return request
    .post(restPrefix + 'processes/')
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .send(processi)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => error.text);
};

const processEdit = (proces, step, group, vName, id) => {
  return request
    .post(
      restPrefix +
        'processEdit/' +
        proces +
        '/' +
        step +
        '/' +
        (group === undefined ? '0' : group) +
        '/' +
        vName +
        '/' +
        id
    )
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error;
    });
};

const saveMessages = comunicazioni => {
  return request
    .post(restPrefix + 'comunications/save/')
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .send(comunicazioni)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error.response;
    });
};

const saveVariables = variables => {
  return request
    .post(restPrefix + 'variables/save/')
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .send(variables)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error.response;
    });
};
const notificheHeader = () => {
  return request
    .post(`${restPrefix}procs/notify/header`)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      var notifyList = json.body.response;
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return notifyList;
    })
    .catch(error => {
      return error;
    });
};

const notifiche = (f, s, id) => {
  var filtro = f ? f : 1;
  var stato = s ? s : 1;
  var restString = id
    ? `${restPrefix}procs/notify/list/${filtro}/${stato}/OPP${id}`
    : `${restPrefix}procs/notify/list/${filtro}/${stato}`;

  return request
    .post(restString)
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .then(json => {
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return json;
    })
    .catch(error => {
      return error;
    });
};

const upload = (proces, step, group, notify, data, num, variableName) => {
  return request
    .post(
      restPrefix +
        'uploadFile/' +
        proces +
        '/' +
        step +
        '/' +
        (group === undefined ? ' ' : group) +
        '/' +
        num +
        '/' +
        (notify === undefined || notify === '' ? 0 : notify)
    )
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('variableName', variableName)
    .send(data)
    .then(json => {
      //return json.body.server;
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      let msgAlert = json.body.response;
      //this.setState({ alert: true, titleAlert, msgAlert });
      return json;
    })
    .catch(error => {
      return error;
    });
};

const call = (proces, step, notify, response, num, hasFile, group) => {
  return request
    .post(
      restPrefix +
        'procs/action/' +
        proces +
        '/' +
        step +
        '/' +
        (group === undefined ? ' ' : group) +
        '/' +
        num +
        '/' +
        (notify === undefined || notify === '' ? 0 : notify) +
        '/'
    )
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .set('hasFile', hasFile)
    .type('json')
    .send(response.variables)
    .then(json => {
      let response = json.body.server;
      const jwt = json.body.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return response;
    })
    .catch(error => {
      return error;
    });
};

const all = async (
  proces,
  step,
  notify,
  response,
  data,
  variableName,
  num,
  hasFile,
  group
) => {
  let server = '';
  server = await call(proces, step, notify, response, num, hasFile, group);

  if (server === 200) {
    // var x = '';
    // x = await upload(proces, step, notify, data, num, variableName);
    // //if(x.response.status && x.response.status===504)

    return upload(proces, step, group, notify, data, num, variableName);
    // .then(ok => console.log(ok))
    // .catch(err => console.log(err));
  } else {
    return 'Errore nel caricamento del/i file/s';
  }
};

const newCalendarEvent = event => {
  return request
    .post(`https://www.googleapis.com/calendar/v3/calendars`)
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .send({ calendarId: 'primary', resource: event })
    .then(json => {
      console.log(json);
    })
    .catch(error => {
      console.log(error);
    });
};

const versions = () => {
  return request
    .get(restPrefix + 'versions/')
    .set('jwt', window.localStorage.getItem('accessToken'))
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Access-Control-Expose-Headers', locationPrefix)
    .type('json')
    .then(json => {
      return json.body.response;
    })
    .catch(error => {
      return error;
    });
};

export {
  restPrefix,
  locationPrefix,
  getPageFromRest,
  getPageFromNotify,
  upload,
  call,
  all,
  getCookie,
  getfromRagg,
  notifiche,
  opportuniyList,
  processEdit,
  // getProcessDesign,
  saveProcs,
  saveMessages,
  delete_cookie,
  setCookie,
  notificheHeader,
  saveVariables,
  varChanged,
  checkUser,
  search,
  registerUser,
  confirmUser,
  lostPsw,
  newPsw,
  changePsw,
  loginWithToken,
  newCalendarEvent,
  classicLogin,
  HelloWorld,
  versions
};
