import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Icon,
  SvgIcon,
  Tooltip,
  Typography,
  ListItemIcon,
  Link,
  AvatarGroup
} from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import Label from 'src/components/Label';
import moment from 'moment/min/moment-with-locales';
import {
  Bell as BellIcon,
  Circle as CircleIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  AlertCircle as alert,
  Info as info,
  Award as award
} from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import { getNotifications } from 'src/slices/notification';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import { appConfig } from 'src/config';
import { Scrollbar } from './scrollbar';
import { useHistory } from 'react-router-dom';
import StyledBadgeNotifications from './StyledBadgeNotifications'
  ;
import { Card } from '@material-ui/core';


// const iconsMap = {
//   1: award,
//   2: info,
//   3: alert
// };

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    color: theme.palette.appBarText.main
  },
  avatar: {
    marginRight: theme.spacing(4),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: "red",
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}));
// const StyledIconButton = styled(IconButton)(
//   ({ theme }) => `
//   color: ${theme.palette.appBarText.main};
//   `
// );

const Notifications = () => {
  const classes = useStyles();
  const { notifications } = useSelector(state => state.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications(1, 1, ''));
  }, [dispatch]);

  const getMoment = deadline => {
    if (deadline) {
      const deadlineMoment = moment(deadline).fromNow();
      return deadlineMoment;
    }
    return undefined;
  };


  const getBadgeColor = (index) => {
    let color;
    switch (index) {
      case 1:
        color = "green";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "red";
        break;
      default:
        color = "green";
    }
    return color;
  };

  return (
    <>
      <Tooltip title={t('notifiche')}>
        <IconButton
          // classes={{ root: classes.icon }}
          className={classes.icon}
          ref={ref}
          onClick={handleOpen}
          size="large"
        >
          <Badge
            badgeContent={notifications ? notifications.length : 0}
            color="error"
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          sx: { width: 550 },

          style: {
            background: 'rgba(245, 245, 245, 0.6)',
            backdropFilter: 'blur(10px)'
          },

        }}
        transitionDuration={0}
      // classes={{ paper: classes.popover }}
      >
        <Box
          sx={{
            alignItems: 'center',
            color: 'primary.main',
            //backgroundColor: 'primary.contrastText',
            display: 'flex',
            justifyContent: 'space-between',
            px: 1.7,
            py: 2,
            fontWeight: 'bold',
          }}

        >
          <Typography color="inherit" variant='h5' sx={{ fontSize: "20px" }}>
            {/* font-family: 'Open Sans', sans-serif */}
            {t('notifiche')}
          </Typography>
        </Box>
        {!notifications || notifications.length == 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {t(appConfig.noNotificationsText)}
            </Typography>
          </Box>
        ) : (
          <Scrollbar sx={{ maxHeight: 500 }}>
            <Card style={{ margin: 12 }} >
              <List disablePadding>
                {notifications.map(notification => {
                  // const Icon = iconsMap[notification.index];

                  return (
                    <>

                      <ListItem
                        divider
                        key={notification.id}
                        sx={{
                          alignItems: 'flex-start',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                            cursor: "pointer"
                          },
                          '& .MuiListItemSecondaryAction-root': {
                            top: '24%'
                          },
                          paddingLeft: 1.3
                        }}
                        onClick={() => {
                          history.push('/app/procesdetail/' +
                            notification.proc +
                            '/' +
                            notification.step +
                            '/' +
                            notification.id +
                            '')
                          setOpen(false)
                        }}
                      >

                        <ListItemAvatar sx={{ mt: 0.5, mr: 2, maxWidth: "200px" }}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Box>
                              <AvatarGroup max={4}>
                                {notification.members.map((n, i) => {
                                  if (i == notification.members.length - 1) {
                                    return (
                                      <>
                                        {/* <Avatar src={n.avatar} />
                                      <Avatar src={n.avatar} /> */}
                                        <StyledBadgeNotifications
                                          color={getBadgeColor(notification.index)}
                                        >
                                          <Avatar src={n.avatar} />
                                        </StyledBadgeNotifications>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <Avatar src={n.avatar} />
                                    )
                                  }


                                })}
                              </AvatarGroup>
                            </Box>
                            <Box sx={{ textAlign: "center", marginLeft: "10px" }}>
                              <Typography
                                color={"textPrimary"}
                                variant="subtitle2"
                              >
                                {notification.kanbanPermissions}
                              </Typography>

                            </Box>
                          </Box>
                        </ListItemAvatar>

                        <ListItemText
                          primary={(
                            <Box
                              sx={{
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: "column",
                                flexWrap: 'wrap'
                              }}
                            >
                              <Box display="flex" sx={{ alignItems: "center" }}>
                                <Typography
                                  sx={{ fontWeight: 600 }}
                                  color={"textPrimary"}
                                  variant="subtitle2"
                                >
                                  {notification.kanbanUserOpenedName}
                                </Typography>
                                <Typography
                                  sx={{ paddingLeft: "5px" }}
                                  variant="body2"
                                >
                                  {t("newJobAdded")}
                                </Typography>
                              </Box>


                              <Link
                                underline="always"
                                variant="body2"
                              >
                                {notification.description}
                              </Link>
                            </Box>
                          )}
                          secondary={(
                            <Typography
                              color="textSecondary"
                              variant="caption"
                            >
                              {getMoment(notification.deadline)}
                            </Typography>
                          )}
                          sx={{ my: 0 }}
                        />

                      </ListItem>

                    </>
                  );
                })}
              </List>
            </Card>
          </Scrollbar>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
