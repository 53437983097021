import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  Menu,
  MenuItem,
  SvgIcon,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import makeStyles from '@mui/styles/makeStyles';
import useAuth from 'src/hooks/useAuth';
import Settings from './Settings';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  text: {
    color: theme.palette.appBarText.main
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/login');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout');
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={user.avatar} />
        <Hidden mdDown>
          <Typography variant="h6"></Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{
          className: classes.popover,
          style: {
            background: 'rgba(245, 245, 245, 0.6)',
            backdropFilter: 'blur(10px)'
          },
        }}
        // getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}

      >
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.2, mb: 0.2 }}>
          <Avatar sx={{
            margin: "15px", height: 30, width: 30, mr: 0
          }}>
          </Avatar>
          <Box sx={{ flexDirection: "column", ml: 0 }}>
            <Typography sx={{ pb: 0 }}>{user.name}</Typography>
            <Typography sx={{ pt: 0 }}>{user.companyDescription}</Typography>
          </Box>
        </Box>
        <Divider />
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          to="/app/account"
        >
          <Divider />
          <SvgIcon fontSize="small" sx={{ marginRight: "5px" }}>
            <AccountCircleIcon />
          </SvgIcon>
          Account
        </MenuItem>
        {/* <Settings>
          <MenuItem />
        </Settings> */}
        <Divider />
        <MenuItem onClick={handleLogout} >
          <SvgIcon fontSize="small" sx={{ marginRight: "5px" }}>
            <LogoutIcon />
          </SvgIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
