import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Link, Typography } from '@mui/material';
import Portal from '@mui/material/Portal';

import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import PrivacyPopup from 'src/components/PrivacyPopup';
import { appConfig } from 'src/config';

const privacy_link = appConfig.privacyLink;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 11000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  }
}));

const CookiesNotification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handlePrivacy = () => {
    setOpenPrivacy(true);
  };

  const handleCloseDialog = () => {
    setOpenPrivacy(false);
  };
  const display_popup = appConfig.displayPopup;

  if (!open) {
    return null;
  }

  return (
    <>
      {openPrivacy ? (
        <PrivacyPopup open={openPrivacy} handleClose={handleCloseDialog} />
      ) : (
        <Portal>
          <div className={classes.root}>
            <Typography variant="body1" color="inherit">
              {t('cookiesText')}{' '}
              {/* <Link
            component="a"
            color="inherit"
            underline="always"
            href="https://stid-platform.firebaseapp.com/privacy"
            target="_blank"
          >
            Privacy Policy
          </Link> */}
              {display_popup == true ? (
                <Link
                  component="a"
                  color="inherit"
                  underline="always"
                  color="secondary"
                  onClick={handlePrivacy}
                  type="button"
                >
                  {t('privacy')}
                </Link>
              ) : (
                <Link
                  component="a"
                  href={privacy_link || '#'}
                  color="secondary"
                >
                  {t('privacy')}
                </Link>
              )}
              .
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                onClick={handleClose}
                variant="contained"
                className={classes.action}
              >
                {t('cookiesOk')}
              </Button>
            </Box>
          </div>
        </Portal>
      )}
    </>
  );
};

export default CookiesNotification;
