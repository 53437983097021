import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  Badge,
  Box,
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ListSubheader from '@mui/material/ListSubheader';
import { viewConfig, appConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { updateLang } from 'src/api/functions-api';
import { useHistory } from 'react-router';
import moment from 'moment';
import { style } from '@mui/system';
import { filter } from 'lodash';
import Blur from 'react-css-blur'

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  icon: {
    color: theme.palette.appBarText.main
  }
}));

const displaySL = appConfig.displaySelectLanguages;
const displayTHEME = viewConfig.theme;

const languages_ = appConfig.availableLanguages;

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    debug: false,
  });
  const { t } = useTranslation();

  const { user } = useAuth();
  const history = useHistory();

  const availableLanguages = languages_.split(',');

  let languageMap = null;
  let selected = localStorage.getItem('i18nextLng');

  // const [select, setSelect] = useState(selected);

  availableLanguages.map(x => {
    switch (x) {
      case 'it':
        languageMap = {
          ...languageMap,
          it: { label: t('italiano'), dir: 'ltr', active: true }
        };
        break;
      case 'en':
        languageMap = {
          ...languageMap,
          en: { label: t('inglese'), dir: 'ltr', active: true }
        };
        break;
      case 'ar':
        languageMap = {
          ...languageMap,
          ar: { label: t('arabo'), dir: 'ltr', active: true }
        };
        break;
      case 'fr':
        languageMap = {
          ...languageMap,
          fr: { label: t('francese'), dir: 'ltr', active: true }
        };
        break;
      case 'pu':
        languageMap = {
          ...languageMap,
          pu: { label: t('punjabi'), dir: 'ltr', active: true }
        };
        break;
    }
  });

  // const languageMap = {
  //   it: { label: t('italiano'), dir: 'ltr', active: true },
  //   en: { label: t('inglese'), dir: 'ltr', active: false },
  //   ar: { label: t('arabo'), dir: 'ltr', active: false },
  //   fr: { label: t('francese'), dir: 'ltr', active: false },
  //   pu: { label: t('punjabi'), dir: 'ltr', active: false }
  // };

  if (
    selected != 'it' &&
    selected != 'en' &&
    selected != 'fr' &&
    selected != 'pu' &&
    selected != 'ar'
  ) {
    selected = 'it';
  }
  // const [menuAnchor, setMenuAnchor] = React.useState(null);
  // React.useEffect(() => {
  //   document.body.dir = languageMap[selected].dir;
  // }, [menuAnchor, selected]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
    saveSettings({
      ...values,
      [field]: value
    })
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  const handleLng = async lng => {
    let langToChange = '';

    switch (lng) {
      case t('italiano'):
        langToChange = 'it';
        break;
      case t('arabo'):
        langToChange = 'ar';
        break;
      case t('inglese'):
        langToChange = 'en';
        break;
      case t('francese'):
        langToChange = 'fr';
        break;
      case t('punjabi'):
        langToChange = 'pu';
        break;
      default:
        langToChange = 'it';
        break;
    }
    i18next.changeLanguage(langToChange);
    // setSelect(langToChange);
    saveSettings({
      ...values,
      lang: langToChange,
      direction: langToChange == 'ar' ? 'rtl' : 'ltr'
    });
    moment.locale(langToChange);
    document.body.dir = langToChange == 'ar' ? 'rtl' : 'ltr';
    const resp = await updateLang(user.id, langToChange);
    if (resp.server == 200) {
      history.go(0);
    }
  };

  return viewConfig.settings == true ? (
    <>
      <Tooltip title={t('impostazioni')}>
        <IconButton
          // classes={{ root: classes.icon }}
          className={classes.icon}
          onClick={handleOpen}
          ref={ref}
          size="large"
        >
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            background: 'rgba(245, 245, 245, 0.6)',
            backdropFilter: 'blur(10px)'
          },
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        {/* <Card
          style={{
            border: 0,
            padding: 5
          }}
        > */}

        <Typography variant="h4" color="textPrimary">
          {t('impostazioni')}
        </Typography>


        {displayTHEME && (
          <Box mt={4}>
            <TextField
              fullWidth
              label={t('tema')}
              name="theme"
              onChange={event => handleChange('theme', event.target.value)}
              select
              SelectProps={{ native: true }}
              value={settings.theme}
              variant="outlined"
            >
              {Object.keys(THEMES).map(theme => (
                <option key={theme} value={theme}>
                  {capitalCase(theme)}
                </option>
              ))}
            </TextField>
          </Box>
        )}
        {user.debugMode && (
          <Box mt={4} ml={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.debug}
                  edge="start"
                  name="direction"
                  onChange={event =>
                    handleChange(
                      'debug',
                      event.target.checked
                    )
                  }
                />
              }
              label="Debug"
            />

          </Box>
        )}

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
          >
            {t('salvaImpostazioni')}
          </Button>
        </Box>
        {/* </Card> */}

      </Popover>
    </>
  ) : null;
};

export default Settings;
