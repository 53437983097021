import React from 'react';

const BottomLogo = props => {

  const path = `/static/LogoProcs.png`
  return <img alt="Logo" src={path} height="30px" {...props} referrerpolicy="no-referrer" />;

};

export default BottomLogo;
