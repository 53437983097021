import axios from 'axios';
import { appConfig } from 'src/config';
import { checkError } from './common';
import { errorHandler } from './common';

const restPrefix = appConfig.restPrefix;
const versionNumber = appConfig.versionNumber;
const http = axios.create({
  baseURL: restPrefix
});
export const getSnippets = () => {
  return http
    .get('/procs/getMethodDescribe/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};
export const getProcessDesign = () => {
  return http
    .get('DESIGNER/GET/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const copy = (proc, step, newCode) => {
  return postCall(
    'DESIGNER/CLONEPROC/' + proc + '/' + step + '/' + newCode + '/',
    null
  );
};



export const copyStep = (currentProc, currentStep, newStep, newProc) => {
  return postCall(
    'DESIGNER/CLONESTEP/' + currentProc + '/' + currentStep + '/' + newStep + '/' + newProc + '/',
    null
  );
};

export const testSQL = sql => {
  return http
    .post('testSQL/', null, {
      params: {
        sql: sql
      }
    })
    .then(response => {
      const risposta = response.data;

      // const jwt = response.jwt;
      // if (jwt !== "") {
      //   setCookie("jwt", jwt, 2);
      // }
      return risposta;
    })
    .catch(error => errorHandler(error));
};

const deleteCall = (url, lastUpdate) => {
  return http
    .delete(url, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber,
        lastUpdate: lastUpdate || ""
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

const postCall = (url, data, lastUpdate) => {
  return http
    .post(url, data, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber,
        lastUpdate: lastUpdate === undefined ? "" : lastUpdate
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveVariable = (codProc, codStep, variable, lastUpdate) => {
  return postCall(
    '/DESIGNER/saveVariable/' + codProc + '/' + codStep + '/',
    variable,
    lastUpdate
  );
};

export const getAllMessageLanguageFromLabel = (label) => {
  return http
    .get('translationsByLabel/', {
      params: {
        label: label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    });
};

export const saveAllMessageLanguagesForLabel = (label, translations) => {
  return postCall(
    'saveAllMessageLanguagesForLabel/' + label + "/",
    translations
  );
};

export const deleteSystemVariable = (codVariable, lastUpdate) => {
  return deleteCall('/DESIGNER/deleteSystemVariable/' + codVariable, lastUpdate);
};
export const deleteVariable = (codProc, codStep, codVariable, lastUpdate) => {
  return deleteCall(
    '/DESIGNER/deleteVariable/' +
    codProc +
    '/' +
    codStep +
    '/' +
    codVariable +
    '/', lastUpdate
  );
};

export const saveSystemVariable = (variable, lastUpdate) => {
  return postCall('/DESIGNER/saveSystemVariable/', variable, lastUpdate);
};

export const saveProcs = processo => {
  return postCall('/DESIGNER/saveProc/', processo, processo.lastUpdate);
};

export const saveStep = (codiceProcesso, step, processo) => {
  return postCall('/DESIGNER/saveStep/' + codiceProcesso, step, processo);
};

export const deleteProcessApi = (processCode, lastUpdate) => {
  return deleteCall('DESIGNER/DELETE/processes/' + processCode, lastUpdate);
};

export const deleteStepOfProcessApi = (processCode, stepCode, lastUpdate) => {
  return deleteCall(
    'DESIGNER/DELETE/processes/' + processCode + '/step/' + stepCode, lastUpdate
  );
};

export const saveMessages = comunicazioni => {
  return postCall('/comunications/save/', comunicazioni);
};

export const saveMessage = (msg, lastUpdate) => {
  return postCall('/DESIGNER/saveMessage/', msg, lastUpdate);
};

export const newMessage = newMessage => {
  return postCall('DESIGNER/CREATE/messages', newMessage);
};

export const deleteMessage = (msgCode, lastUpdate) => {
  return deleteCall('DESIGNER/DELETE/messages/' + msgCode, lastUpdate);
};

export const copyMessage = (procCode, newCode) => {
  if (newCode === undefined || newCode === '') return undefined;
  return postCall(
    'DESIGNER/CLONE/messages/' + procCode + '/' + newCode + '/',
    {}
  );
};

export const saveVariables = variables => {
  return http
    .post('/variables', variables, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getMenu = () => {
  return http
    .get('DESIGNER/get/menu', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveMenu = menu => {
  const root = menu.root;
  return http
    .post(
      'DESIGNER/set/menu',
      { root },
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: menu.lastUpdate === undefined ? "" : menu.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveOrganization = organization => {
  const root = organization.root;
  return http
    .post(
      'DESIGNER/set/organization',
      { root },
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: organization.lastUpdate === undefined ? "" : organization.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getOrganization = () => {
  return http
    .get('DESIGNER/get/organization', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getNodes = () => {
  return http
    .get('auth/get/nodes', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveNodes = nodes => {
  return http
    .post(
      'auth/set/nodes',
      nodes,
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber,
          lastUpdate: nodes.lastUpdate === undefined ? "" : nodes.lastUpdate
        }
      }
    )
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const getDiagram = id => {
  return http
    .get('/camunda/xml/taskId/' + id)
    .then(response => {
      if (response.data.response.bpmn20Xml) {
        return response.data.response.bpmn20Xml;
      } else {
        return response;
      }
    })
    .catch(error => errorHandler(error));
};

export const getExportProcs = () => {
  return http
    .get('/DESIGNER/EXPORT/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      return resp;
    })
    .catch(error => errorHandler(error));
};

export const importProcs = data => {
  return http
    .post('/DESIGNER/IMPORT', data, {
      headers: {
        jwt: localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.status;

      return resp;
    })
    .catch(error => errorHandler(error));
};

export const expireCache = () => {
  return http
    .post('/expirecache')
    .then(response => {
      return response;
    })
    .catch(error => error);
};

export const getAllTranslations = () => {
  return http
    .get('/allTranslations', {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};


// export const saveAllMessageLanguagesForLabel = (label, traduzioni) => {
//   return postCall(
//     'saveAllMessageLanguagesForLabel/' + label + "/",
//     traduzioni

//   );

export const searchKeysWithLike = (label) => {
  return http
    .get('/searchKeysWithLike', {
      params: {
        label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const translationByLabel = (label) => {
  return http
    .get('/translationsByLabel', {
      params: {
        label
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};


export const saveAllTranslations = allTranslations => {
  return http
    .post(
      '/saveAllMessageLanguagesForLabel',
      allTranslations,
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber
        }
      }
    )
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));

};


export const avaibleLanguages = () => {
  return http
    .get('/avaibleLanguages', {
      headers: {
        jwt: "fake",
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const deploy = () => {
  return http
    .post('/deploy')
    .then(response => {
      return response;
    })
    .catch(error => error);
};


