import axios from 'axios';
import { appConfig } from 'src/config';
import { checkError } from './common';
import { errorHandler } from './common';

const restPrefix = appConfig.restPrefix;
const versionNumber = appConfig.versionNumber;

const http = axios.create({
  baseURL: restPrefix
});

export const search = stringSearch => {
  return http
    .get('/procs/search/', {
      stringSearch,
      jwt: window.localStorage.getItem('accessToken')
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const varChanged = async (
  proces,
  step,
  group,
  key,
  variables,
  notify
) => {
  const g = group == undefined || group == '' ? 0 : group;
  return http
    .post(`/procs/event/${proces}/${step}/${g}/${key}/${notify}/`, variables, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp.response;
    })
    .catch(error => errorHandler(error));
};

export const opportuniyList = () => {
  return http
    .get('/procs/opportunity/list/', {
      jwt: window.localStorage.getItem('accessToken')
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp.response;
    })
    .catch(error => errorHandler(error));
};

export const getfromRagg = id => {
  return http
    .post('/procs/fromRagg/OPP/' + id, null, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp.response;
    })
    .catch(error => errorHandler(error));
};

export const processEdit = (proces, step, group, response, vName, id) => {
  const g = group == undefined || group == '' ? 0 : group;
  let payload = response.variables;

  return http
    .post(`/processEdit/${proces}/${step}/${g}/${vName}/${id}`, payload, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const importFile = (proces, step, data) => {
  return http
    .post('/procs/importFile/' + proces + '/' + step, data, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
  // .then(response => response)
  // .then(error => error)
};

export const downloadFile = async (
  taskId,
  variableName,
  processo,
  step,
  group
) => {
  return http
    .get('download/', {
      params: {
        taskId: taskId,
        variableName: variableName,
        proc: processo,
        step: step,
        group: group
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp.response;
    })
    .catch(error => errorHandler(error));
};

export const downloadFileByUUID = async uuid => {
  return http
    .get('download/file/fromUUID', {
      params: {
        UUID: uuid
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp.response;
    })
    .catch(error => errorHandler(error));
};

export const updateLang = async (userId, lang) => {
  return http
    .post(
      `/LOGIN/UPDATEUSER/` + userId,
      { lang: lang },
      {
        headers: {
          jwt: window.localStorage.getItem('accessToken'),
          versionNumber
        }
      }
    )
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};

export const saveFirebaseToken = async firebaseToken => {
  return http
    .post(`/save/firebaseToken/` + firebaseToken, null, {
      headers: {
        jwt: window.localStorage.getItem('accessToken'),
        versionNumber
      }
    })
    .then(response => {
      const resp = response.data;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => errorHandler(error));
};
